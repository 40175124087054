<template>
  <router-view/>
</template>
<style>
body {
  background:#131825;
  background-repeat: no-repeat;
  /* background-color: black; */
  min-height: 100vh;
}
</style>
