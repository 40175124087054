import { reactive } from "vue";
import { http } from "@/misc/http";

const config = {
  state: reactive({
    config: null,
  }),

  async load() {
    this.state.config = await http.get("/config");
  },
};

export { config };
