<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <card-comp>
    <form>
      <div v-if="!license" class="mb-3">
        No license loaded
      </div>
      <div v-if="license && !newMode" class="mb-3">
        Expire: {{ license.expiry ? format_datetime(Date.parse(license.expiry)) + " (offline license)" : "Never" }}
      </div>
      <div v-if="license" class="mb-3">
        <label for="bundle" class="form-label">License bundle</label>
        <select v-model="license.bundle" class="form-select">
          <option v-for="p in license_info.plans" :key="p.id" :value="p.id">{{ p.name }}</option>
        </select>
      </div>
      <div v-if="license && !license.bundle" class="d-flex flex-wrap gap-3 mb-3">
        <div v-for="f in license_info.features" :key="f" class="form-check">
          <input class="form-check-input" type="checkbox" value="" :id="f" v-model="features[f]">
          <label class="form-check-label" :for="f">{{ f }} </label>
        </div>
      </div>
      <div v-if="license && plan" class="d-flex flex-wrap gap-3 mb-3">
        <label class="form-label">Included features</label><br/>
        <div v-for="f in plan.features" :key="f" class="form-check">
          <input class="form-check-input" type="checkbox" :id="f" v-model="includedFeatures[f]">
          <label class="form-check-label" :for="f">{{ f }} </label>
        </div>
      </div>
      <div v-if="license && plan" class="d-flex flex-wrap gap-3 mb-3">
        <label class="form-label">Extra features</label><br/>
        <div v-for="f in license_info.features.filter((ft) => !plan.features.includes(ft))" :key="f" class="form-check">
          <input class="form-check-input" type="checkbox" value="" :id="f" v-model="extraFeatures[f]">
          <label class="form-check-label" :for="f">{{ f }} </label>
        </div>
      </div>
      <div class="d-flex gap-2">
        <slot :valid="valid"/>
      </div>
    </form>
  </card-comp>

</template>

<script>
/* eslint-disable vue/no-mutating-props */

import CardComp from "@/components/ui/CardComp";
import {format_datetime} from "@/misc/util";

export default {
  components: {CardComp},
  props: ["license_info", "license", "disabled", "newMode"],
  data() {
    return {
      features: {},
      includedFeatures: {},
      extraFeatures: {},
      plan: null,
    }
  },
  watch: {
    license() {
      if (this.license)
        this.features = this.license.features.reduce((a, v) => ({...a, [v]: true}), {});
        this.extraFeatures = this.license.extra_features.reduce((a, v) => ({...a, [v]: true}), {});
        if (this.license.bundle) {
          this.plan = this.license_info.plans.find(p => p.id === this.license.bundle);
          this.includedFeatures = this.plan.features.reduce((a, v) => ({...a, [v]: !this.license.disabled_features.includes(v)}), {});
          this.features = {};
        } else {
          this.plan = null;
        }
    },
    "license.bundle": {
      handler() {
        if (this.license.bundle) {
          this.plan = this.license_info.plans.find(p => p.id === this.license.bundle);
          this.includedFeatures = this.plan.features.reduce((a, v) => ({...a, [v]: !this.license.disabled_features.includes(v)}), {});
          this.features = {};
        } else {
          this.plan = null;
        }
      }
    },
    features: {
      deep: true,
      handler() {
        // eslint-disable-next-line no-unused-vars
        this.license.features = Object.entries(this.features).filter(([k, v]) => v).map(([k, v]) => k);
      }
    },
    extraFeatures: {
      deep: true,
      handler() {
        // eslint-disable-next-line no-unused-vars
        this.license.extra_features = Object.entries(this.extraFeatures).filter(([k, v]) => v).map(([k, v]) => k);
      }
    },
    includedFeatures: {
      deep: true,
      handler() {
        this.license.disabled_features = this.plan.features.filter((v) => !this.includedFeatures[v]);
      }
    }
  },
  computed: {
    valid() {
      if (!this.license) return null;
      const legacyValid = this.license.features.length > 0;
      const currentValid = this.license.bundle; // TODO check extra/disabled features intersection
      return legacyValid || currentValid;
    }
  },
  methods: {
    format_datetime,

  }
}
</script>
