<template>
  <card-comp title="License">
    <div v-if="error" class="text-danger">{{ error }}</div>

    <div class="mb-3">
      <license-key v-if="user.license_key" :license-key="user.license_key"/>
      <p v-else>No license issued yet.</p>
    </div>
    <license-form
        v-if="license_info"
        :license_info="license_info"
        :license="license"
        :new-mode="!user.license_key"
    >
      <template v-slot:default="form">
        <button v-if="!user.license_key" @click="licenseAction('issue')" type="button" class="btn btn-primary" :disabled="loading || !form.valid">
          <l-spin v-if="loading=='issue'"/>
          Issue license
        </button>
        <button v-if="user.license_key && license" @click="licenseAction('update')" type="button" class="btn btn-primary" :disabled="loading || !form.valid">
          <l-spin v-if="loading=='update'"/>
          Update license
        </button>
        <button v-if="user.license_key && license && license.expiry" @click="licenseAction('update-clear')" type="button" class="btn btn-primary" :disabled="loading || !form.valid">
          <l-spin v-if="loading=='update-clear'"/>
          Update and clear expiry
        </button>
        <button v-if="user.license_key && !license" @click="loadLicense" type="button" class="btn btn-primary" :disabled="loading">
          <l-spin v-if="loading=='loading'"/>
          Load license
        </button>
        <button v-if="user.license_key" @click="licenseAction('revoke')" type="button" class="btn btn-primary" :disabled="loading">
          <l-spin v-if="loading=='revoke'"/>
          Revoke license
        </button>
      </template>

    </license-form>

  </card-comp>
</template>

<script>
import CardComp from "@/components/ui/CardComp";
import {http} from "@/misc/http";
import LicenseKey from "@/components/ui/LicenseKey";
import LSpin from "@/components/ui/LSpin";
import LicenseForm from "@/pages/users/LicenseForm";
import {auth} from "@/state/auth";

export default {
  components: {LicenseForm, LSpin, LicenseKey, CardComp},
  props: ["user"],
  emits: ["license-change"],
  data() {
    return {
      license_info: null,
      license: null,
      error: "",
      loading: false,

    }
  },

  async created() {
    try {
      this.license_info = await http.get('/admin/license_info');
      if (!this.user.license_key) {
        this.license = {
          expire: null,
          purpose: "",
          features: [],
          bundle: null,
          extra_features: [],
          disabled_features: [],
        }
      }
    } catch (e) {
      this.error = e.message;
    }
  },

  methods: {
    async licenseAction(action) {
      if (confirm("Are you sure to " + action + " license for " + this.user.email)) {
        this.error = '';
        this.loading = action;
        try {
          if (action === "issue")
            await http.post('/admin/users/' + this.user.id + '/license', this.license);
          else if (action === "update") {
            await http.patch('/admin/users/' + this.user.id + '/license', this.license);
          } else if (action === "update-clear") {
            this.license.expiry = null;
            await http.patch('/admin/users/' + this.user.id + '/license', this.license);
          } else if (action === "revoke") {
            await http.delete('/admin/users/' + this.user.id + '/license');
            this.license = {
              expire: null,
              purpose: "",
              features: [],
            }
          }
          // this.$router.go();
          await auth.loadUser();
          this.$emit('license-change');
        } catch (e) {
          this.error = e.message;
        }
        this.loading = false;
      }
    },
    async loadLicense() {
      this.error = '';
      this.loading = "loading";
      try {
        this.license = await http.get('/admin/users/' + this.user.id + '/license');
      } catch (e) {
        this.error = e.message;
      }
      this.loading = false;
    },
  },

}
</script>
