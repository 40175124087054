<template>
  <div class="sticky-top">
    <!-- Logo -->
    <div class='d-flex flex-row'>
      <div class="d-md-none mt-2 ml-auto" style="width: 90%">
      <img src="@/assets/logo.svg" alt="" class="img-fluid my-3">
    </div>
    <!-- Hamburger menu button -->
      <button @click="toggleMenu" class="d-md-none mr-auto bg-transparent text-light nav">
        <i class="bi bi-list" style="font-size: 32px;"></i>
      </button>
    </div>

    <!-- Mobile menu content -->
    <div class="d-md-none background">
      <div v-if="isMenuOpen" class="menu">
        <slot/>
        <div class="mt-4"></div>
      </div>
    </div>

    <!-- Sidebar -->
    <aside :class="['sidebar', { 'show': isSidebarVisible }]">
      <div class="mt-2 " style="width: 90%">
        <img src="@/assets/logo.svg" alt="" class="img-fluid my-3">
      </div>
      <hr class="bg-light" style="height: 2px">

      <slot/>

      <div class="mt-4"></div>
      <feedback-form/>
    </aside>
  </div>
</template>


<script>
import {auth} from "@/state/auth";
import FeedbackForm from "@/components/feedback/FeedbackForm";

export default {
  components: {FeedbackForm},
  computed: {
    user() {
      return auth.state.user;
    }
  },
  data() {
    return {
      isSidebarVisible: false,
      isMenuOpen: false
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
};

</script>
<style scoped>
.sidebar {
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #212936;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-left: -12px;
}
.nav{
  border: 0;
}
/* .row{
  padding-left:0
} */

@media (max-width: 765px) {
  .sidebar {
    display: none;
  }
  .d-md-none {
    display: block;
  }
  .show {
    display: block;
  }
}
.background {
  background-color: black;
}
.footer {
  font-size: 0.8rem;
  color: rgba(248,249,250,0.5);
}
</style>