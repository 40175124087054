<template>
  <div class="accordion-item">
    <h4 class="accordion-header">
      <a :href="href" class="accordion-button text-decoration-none" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+id">
        {{ title }}
      </a>
    </h4>
    <div :id="id" class="accordion-collapse collapse" :class="{'show':!!show}" :data-bs-parent="'#'+parent">
      <div class="accordion-body">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "parent", "title", "show", "href"],
}
</script>

<style scoped>

</style>