<template>
  <div v-if="error" class="text-danger">{{ error }}</div>
  <l-spin v-if="loading"/>
  <div v-if="user" class="row mb-3">
    <div class="col d-flex align-items-stretch">
      <user-info :user="user" class="w-100"/>
    </div>
    <div class="col d-flex align-items-stretch">
      <license-actions @license-change="loadUser" :user="user" class="w-100"/>
    </div>
  </div>

</template>

<script>
import {http} from "@/misc/http";
import UserInfo from "@/pages/users/UserInfo";
import LicenseActions from "@/pages/users/LicenseActions";
import LSpin from "@/components/ui/LSpin";

export default {
  components: {LSpin, LicenseActions, UserInfo},
  props: ["id"],
  data() {
    return {
      user: null,
      error: "",
      loading: false,
    }
  },
  created() {
    this.loading = true;
    this.loadUser();
    this.loading = false;
  },
  methods: {
    async loadUser() {
      try {
        this.user = await http.get("/admin/users/" + this.id);
      } catch (e) {
        this.error = e.message;
      }
    },

  }


}

</script>
