<template>
  <div class="row justify-content-center mt-5">

    <card-comp title="Create new user" style="max-width: 540px;">
      <div v-if="error" class="text-danger">{{ error }}</div>

      <form @submit.prevent="submit">
        <div class="mb-2">
          <label for="email" class="form-label">{{ signupMode ? 'Your work email' : 'Email address' }}</label>
          <input v-model="email" type="email" class="form-control" id="email">
        </div>
        <div class="mb-2">
          <label for="purpose" class="form-label">Description (purpose for registration)</label>
          <textarea v-model="purpose" class="form-control" id="purpose" rows="3"></textarea>
        </div>
        <div class="form-check mb-2">
          <input class="form-check-input" type="checkbox" value="" id="admin" v-model="admin">
          <label class="form-check-label" for="admin">Create administrator</label>
        </div>

        <button @click.prevent="submit" :disabled="!isValid || !!loading" type="submit" class="btn btn-primary mb-3">
          <l-spin v-if="loading"/>
          Create
        </button>
      </form>

    </card-comp>
  </div>
</template>

<script>
import CardComp from "@/components/ui/CardComp";
import LSpin from "@/components/ui/LSpin";
import {http} from "@/misc/http";
import {auth} from "@/state/auth";

export default {
  components: {LSpin, CardComp},
  data() {
    return {
      email: "",
      purpose: "Manually created by " + auth.state.user.email,
      admin: history.state?.admin,
      loading: false,
      error: false,
    }
  },
  methods: {
    async submit() {
      if (this.admin && !confirm("You are creating administrator for Portal, are you sure?")) return;
      this.loading = true;

      try {

        const user = await http.post('/admin/users', {
          email: this.email,
          purpose: this.purpose,
          admin: this.admin,
        });

        this.$router.push({
          name: "user",
          params: {
            id: user.id
          }
        });

      } catch (e) {
        this.error = e.message;
      }

      this.loading = false;

    },

  },
  computed: {
    isValid() {
      function validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
      }

      return this.email && validateEmail(this.email) && this.purpose;
    },
  }

}
</script>
