<template>
  <page-header title="Get started">
    Welcome to Alchemist Portal!
  </page-header>

  <card-comp v-if="!user?.license_key" class="mb-5">
    <p>Thank you for registration on Alchemist Portal!</p>
    <p>We are issuing license for you to use Alchemist, we'll inform you by email when it will be ready, so you'll be able to download and use Alchemist.</p>
  </card-comp>

  <div v-if="error" class="text-danger">{{ error }}</div>
  <l-spin v-if="loading"/>
  <div v-if="welcome" v-html="welcome"/>

</template>

<script>
import {auth} from "@/state/auth";
import PageHeader from "@/components/PageHeader";
import {http} from "@/misc/http";
import LSpin from "@/components/ui/LSpin";
import CardComp from "@/components/ui/CardComp";

export default {
  components: {CardComp, LSpin, PageHeader},
  data() {
    return {
      welcome: null,
      error: "",
      loading: false,
    }
  },
  async created() {
    this.loading = true;
    try {
      this.welcome = await http.get("/welcome");
    } catch (e) {
      this.error = e.message;
    }
    this.loading = false;
  },
  computed: {
    user() {
      return auth.state.user;
    },

  },


}
</script>

