<template>
  <div v-if="error" class="text-danger">{{ error }}</div>
  <div v-if="user.id != logged_user.id" class="d-flex gap-2">
    <button @click="approveUser" v-if="user.status=='registered'" class="btn btn-primary">Approve registration</button>
    <button @click="approveUser" v-if="user.status=='blocked'" class="btn btn-primary">Unblock user</button>
    <button @click="blockUser" v-if="user.status!='blocked'" class="btn btn-primary">Block user</button>
    <button @click="deleteUser" class="btn btn-danger">Delete user</button>
  </div>
  <div v-else>This is your account, you can not perform any action on it.</div>
</template>

<script>

import {http} from "@/misc/http";
import {auth} from "@/state/auth";

export default {
  props: ["user"],
  data() {
    return {
      error: "",
    }
  },
  methods: {
    async approveUser() {
      // const purpose = prompt("Please enter allowed purpose to use Alchemist for this user", this.user.permitted_purpose || "for nothing");
      // if (!purpose) return;
      if (!confirm("Are you sure to allow user to download and use Alchemist?")) return;
      try {
        await http.post('/admin/users/' + this.user.id + '/actions', {
          action: "approve",
        });
        this.$router.go();
      } catch (e) {
        this.error = e.message;
      }
    },
    async blockUser() {
      if (this.user.license_key) {
        alert("Please revoke license first.");
        return;
      }
      //     && !confirm("You are blocking user with active license (recommended to revoke license first), are you sure?")
      // ) return;
      // const reason = prompt("Please enter block reason", "Malicious or spam");
      // if (!reason) return;
      try {
        await http.post('/admin/users/' + this.user.id + '/actions', {
          action: "block",
        });
        this.$router.go();
      } catch (e) {
        this.error = e.message;
      }
    },
    async deleteUser() {
      if (this.user.license_key) {
        alert("Please revoke license first.")
        return;
      }
      if (!confirm("Are you sure to delete user? This operation can not be undone!"))
        return;
      try {
        await http.delete('/admin/users/' + this.user.id);
        this.$router.replace({name: "users"});
      } catch (e) {
        this.error = e.message;
      }

    },
  },
  computed: {
    logged_user() {
      return auth.state.user;
    }
  }
}
</script>
