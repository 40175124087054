<template>
  <h1 class="mt-3 text-light">{{ title }}</h1>
  <h5 class="mt-1 mb-4 text-light"><slot/></h5>
</template>

<script>
export default {
  props: ["title"],
}
</script>

