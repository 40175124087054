<template>
  <div class="container-fluid">
    <div class="mt-5 w-100 text-center">
      <l-spin/>
      Loggin to Alchemist Portal<br>
    </div>
  </div>
</template>

<script>
import {http} from "@/misc/http";
import {auth} from "@/state/auth";
import LSpin from "@/components/ui/LSpin";

export default {
  components: {LSpin},
  props: ["login_token", "redirect"],
  async mounted() {
    if (auth.isLoggedIn()) {
      this.$router.push(this.redirect ? decodeURIComponent(this.redirect) : {name: "main"});
    } else try {
      const token = await http.post('/auth/login', this.login_token);
      await auth.login(token);
      this.$router.push(this.redirect ? decodeURIComponent(this.redirect) : {name: "main"});
    } catch (e) {
      auth.logout();
      this.$router.push({name: "signin", state: {message: "Login link is expired or invalid, please sign in again."}});
    }
  },
  computed: {
    user() {
      return auth.state.user;
    }
  }
}
</script>
