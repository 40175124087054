<template>
  <span class="d-flex gap-2 flex-nowrap text-truncate">{{ licenseKey.slice(0, 16) + '...' }}<button @click="doCopy" class="btn btn-sm btn-outline-secondary"><i class="bi bi-clipboard"/> Copy</button></span>

</template>

<script>
export default {
  props: ["licenseKey"],
  methods: {
    doCopy() {
      navigator.clipboard.writeText(this.licenseKey);
    }
  }
}
</script>
