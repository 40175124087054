<template>
  <page-header title="Users">
    Manage users of the Alchemist Portal
  </page-header>
  <div v-if="error" class="text-danger">{{ error }}</div>
  <l-spin v-if="loading"/>
  <card-comp
      v-for="u in users"
      :key="u"
      :title="u.title"
      class="mb-3"
  >
    <table-comp
        :header="u.header"
        :rows="u.rows"
        @rowClick="$router.push({name: 'user', params:{id:$event[0]}})"
    />
    <button v-if="u.name=='admin'" @click="$router.push({name: 'createUser', state:{admin:true}})" class="btn btn-primary">Create Admin</button>
    <button v-if="u.name=='approved'" @click="$router.push({name: 'createUser'})" class="btn btn-primary">Register new user</button>
  </card-comp>
</template>

<script>

import {http} from "@/misc/http";
import TableComp from "@/components/ui/TableComp";
import CardComp from "@/components/ui/CardComp";
import LSpin from "@/components/ui/LSpin";
import PageHeader from "@/components/PageHeader";

export default {
  components: {PageHeader, LSpin, CardComp, TableComp},
  data() {
    return {
      users: null,
      error: "",
      loading: false,
    }
  },

  async created() {
    let users;
    this.loading = true;
    try {
      users = await http.get("/admin/users");
    } catch (e) {
      this.error = e.message;
      this.loading = false;
      return;
    }
    this.loading = false;
    this.users = {
      admin: {
        name: "admin",
        title: "Admins",
        header: ["ID", "Email", "Description"],
        rows: users
            .filter(i => i.status == "admin")
            .map(i => [i.id, i.email, i.profile.description]),
      },
      waiting: {
        name: "waiting",
        title: "Waiting for approval",
        header: ["ID", "Email", "Purpose"],
        rows: users
            .filter(i => i.status == "registered")
            .map(i => [i.id, i.email, i.profile.description]),
      },
      approved: {
        name: "approved",
        title: "Approved",
        header: ["ID", "Email", "Purpose", "Permitted purpose", "License key"],
        rows: users
            .filter(i => i.status == "verified")
            .map(i => [i.id, i.email, i.profile.description, i.permitted_purpose, i.license_key ? i.license_key.slice(0, 16) + '...' : 'No license']),
      },
      blocked: {
        name: "blocked",
        title: "Blocked",
        header: ["ID", "Email", "Purpose"],
        rows: users
            .filter(i => i.status == "blocked")
            .map(i => [i.id, i.email, i.profile.description]),
      }
    }
    for (let k of Object.keys(this.users)) {
      if (this.users[k].rows.length == 0 && this.users[k].name != "approved")
        delete this.users[k];
      else
        this.users[k].title += " (" + this.users[k].rows.length + ")";
    }
  },
  computed: {
    header() {
      return ["ID", "Email", "Status"];
    },
    rows() {
      return this.users.map(i => [i.id, i.email, i.status])
    },

  },
  methods: {
    getUsers(which) {
      if (!this.users) return null;
      console.log(which)
    }

  },


}
</script>
