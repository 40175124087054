<template>
  <feedback-dialog ref="feedbackForm"/>
  <div class="container-fluid">
    <div class="row min-vh-100 flex-wrap flex-md-nowrap">
      <nav-bar class="col-12 col-md-3 col-xxl-2">
        <main-menu/>
      </nav-bar>
      <div class="col mx-4">
        <login-info/>
        <main class="mt-5">
          <router-view/>
        </main>
      </div>
    </div>
  </div>

</template>

<script>
import MainMenu from "@/layout/MainMenu";
import NavBar from "@/components/navigation/NavBar";
import LoginInfo from "@/components/LoginInfo";
import FeedbackDialog from "@/components/feedback/FeedbackDialog";
import {computed} from "vue";

export default {
  components: {FeedbackDialog, LoginInfo, NavBar, MainMenu},
  provide() {
    return {
      feedbackForm: computed(() => this.$refs.feedbackForm),
    }

  },
}
</script>
