import {createRouter, createWebHistory} from 'vue-router'
import MainLayout from "@/layout/MainLayout";
import AuthForm from "@/pages/auth/AuthForm";
import LoginPage from "@/pages/auth/LoginPage";
import MainPage from "@/pages/main/MainPage";
import UsersPage from "@/pages/users/UsersPage";
import DownloadsPage from "@/pages/downloads/DownloadsPage";
import {auth} from "@/state/auth";
import UserPage from "@/pages/users/UserPage";
import LogoutPage from "@/pages/auth/LogoutPage";
import CreateUser from "@/pages/users/CreateUser";
import OfflineLicense from "@/pages/downloads/OfflineLicense";
import EulaPage from "@/pages/EulaPage";
import LicensePage from "@/pages/license/LicensePage";

const routes = [
  {path: "/", name: "signin", component: AuthForm},
  {path: "/logout", name: "logout", component: LogoutPage},
  {path: "/eula", name: "eula", component: EulaPage},
  {
    path: "/", component: MainLayout, children: [
      {path: "main", name: "main", component: MainPage},
      {path: "users", name: "users", component: UsersPage},
      {path: "users/create", name: "createUser", component: CreateUser},
      {path: "users/:id(\\d+)", name: "user", component: UserPage, props: true},
      {path: "downloads", name: "downloads", component: DownloadsPage},
      {path: "downloads/:ver", name: "downloadsV", component: DownloadsPage, props: true},
      {path: "license", name: "license", component: LicensePage},
      {path: "offline", name: "offline", component: OfflineLicense},
    ]
  },

  {
    path: "/login/:login_token", name: "login", component: LoginPage, props: route => ({
      login_token: route.params.login_token,
      redirect: route.query.redirect,
    })
  },
  {path: "/:notFound(.*)", redirect: {name: "main"}},


]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(to => {
  if (["login", "logout", "eula"].includes(to.name)) return true;

  if (!auth.isLoggedIn()) {
    if (to.name == 'signin') return true;

    return {
      name: "signin",
      query: {
        redirect: encodeURIComponent(window.location.pathname + window.location.search),
      },
      state: {message: "You are not logged in, please sign-in first."}
    }

  } else {
    if (to.name == 'signin') return {name: "main"};
    const access = !!{
      users: ["admin"].includes(auth.state.user?.status),
      user: ["admin"].includes(auth.state.user?.status),
      createUser: ["admin"].includes(auth.state.user?.status),
      downloads: ["admin", "verified"].includes(auth.state.user?.status),
      downloadsV: ["admin", "verified"].includes(auth.state.user?.status),
      offline: ["admin", "verified"].includes(auth.state.user?.status),
      main: true,
      license: true,
    }[to.name];
    return access || {name: "main"};
  }
});

export default router
