<template>


  <div v-if="error" class="text-danger">{{ error }}</div>
  <div class="row mt-2">

    <div class="col-lg-6 p-3">
      <div class="border rounded border-secondary p-3 h-100">

        <div class="row">
          <div class="col"><h6>License status:</h6></div>

          <div v-if="user.license_key" class="col text-success">Active</div>
          <div v-else class="col text-warning">Waiting to be issued</div>
        </div>

        <div class="row mt-2">
          <div class="col"><h6>License key:</h6></div>
          <div class="col">
            <license-key v-if="user.license_key" :license-key="user.license_key"/>
            <span v-else>-</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="user.license_key" class="col-lg-3 p-3">
      <div class="border rounded border-secondary p-3 h-100">
        <h6>Your PC is offline?</h6>
        Follow the steps to make or renew your license to offline.<br>
        <!--          <button @click="getOfflineLicense" class="btn btn-primary">[dev] offline</button>-->
        <a href="#" @click="$emit('show-offline')" class="mt-3 link-light d-block mt-2">Create or renew offline license</a>
      </div>
    </div>

    <div class="col-lg-3 p-3">
      <div class="border rounded border-secondary p-3 h-100">
        <h6>Terms of service</h6>
        Please review important legal information<br>
        <a href="#" class="mt-3 link-light d-block mt-2" @click="$emit('show-eula')">Show EULA</a>
      </div>
    </div>


  </div>

</template>

<script>
import {auth} from "@/state/auth";
import LicenseKey from "@/components/ui/LicenseKey";

export default {
  components: {LicenseKey},
  emits: ["show-eula"],
  data() {
    return {
      error: "",
      loading: false,
      fingerprint: "",

    }
  },
  computed: {
    user() {
      return auth.state.user;
    },
  },
  methods: {
    async getOfflineLicense() {
      const hash = await this.getSHA256Hash(this.user.license_key);
      this.$router.push({name: "offline", query: {hash: hash}});
    },

    async getSHA256Hash(input) {
      const textAsBuffer = new TextEncoder().encode(input);
      const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray
          .map((item) => item.toString(16).padStart(2, "0"))
          .join("");
    },

  }

}
</script>
