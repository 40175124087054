<template>
  <nav-link to="main" icon="bi-rocket-takeoff-fill">Get Started</nav-link>
  <nav-link v-if="['admin'].includes(user?.status)" to="users" icon="bi-people-fill">Users</nav-link>
  <nav-link v-if="['admin', 'verified'].includes(user?.status)" to="downloads" icon="bi-cloud-arrow-down-fill">Downloads</nav-link>
  <nav-link to="license" icon="bi-key-fill">License</nav-link>
  <nav-link to="https://docs.getalchemist.io/" icon="bi-folder-symlink-fill">Documentation</nav-link>
</template>
<script>
import NavLink from "@/components/navigation/NavLink";
import {auth} from "@/state/auth";

export default {
  components: {NavLink},
  computed: {
    user() {
      return auth.state.user;
    }
  }
}
</script>
