<template>
  <hr class="bg-light mt-auto" style="height: 2px">
  <div class="p-2">
    <h5>Have a question?</h5>
    <button id="contact" class="btn btn-outline-secondary" @click="feedbackForm.value.show('Your question', '', '')">Contact us</button>
  </div>
  <hr class="bg-light" style="height: 2px">

</template>

<script>
export default {
  name: "FeedbackForm",
  inject: ['feedbackForm'],

}
</script>

<style scoped>

</style>