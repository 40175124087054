<template>
  <b-modal ref="eula_raw" title="Alchemist End-User License Agreement" okbutton="Close" :cancelbutton="false">
    <div v-html="eula"/>
  </b-modal>

  <b-modal ref="offline" title="Alchemist Offline License" okbutton="Close" :cancelbutton="false">
    <p>An offline license is only necessary if you are unable to activate your license key in the standard way, for example, if you do not have an internet connection on the machine where you run Alchemist.</p>
    <p>The offline license has a limited lifetime of {{ config.ALP_LICENSE_OFFLINE_EXPIRE_MONTH }} months.</p>

    <p>To create or renew your offline license, please run the following command:</p>
    <pre>alchemist license offline</pre>
    <p>Then, follow the provided link on the machine where you can log into the Alchemist Portal.</p>

    <p class="fw-bold">Please do not create an offline license if you can activate your key in the standard way!</p>
  </b-modal>

  <page-header title="License">
    Manage your Alchemist license
  </page-header>

  <div v-if="error" class="text-danger">{{ error }}</div>
  <l-spin v-if="loading=='eula'"/>
  <license-actions v-if="eula" @show-eula="$refs.eula_raw.show()" @show-offline="$refs.offline.show()" class="w-100"/>

  <hr class="bg-dark w-100 mt-5 mt-lg-4" style="height: 2px">

  <h5 class="mt-5 mt-lg-4">Browse our license plans</h5>

  <l-spin v-if="loading=='plans'"/>

  <div v-if="plans" class="row mt-2">
    <license-plan v-for="p in plans" :key="p" :plan="p" @show-feedback="feedbackForm.value.show('Plan activation request', null, 'Please activate '+p.title+' license plan for me.')"/>
  </div>

</template>

<script>
import LicenseActions from "./LicenseActions";
import BModal from "@/components/ui/BModal";
import LSpin from "@/components/ui/LSpin";
import {http} from "@/misc/http";
import {config} from "@/state/config";
import PageHeader from "@/components/PageHeader";
import LicensePlan from "@/pages/license/LicensePlan";
import {auth} from "@/state/auth";


export default {
  components: {LicensePlan, PageHeader, LSpin, BModal, LicenseActions},
  inject: ['feedbackForm'],
  data() {
    return {
      error: "",
      loading: false,
      eula: null,
      plans: null,
    }
  },

  async created() {
    try {
      this.loading = "eula";
      this.eula = await http.get("/eula/full");
      this.loading = "plans";
      this.plans = await http.get("/license_plans");
    } catch (e) {
      this.error = e.message;
    }
    this.loading = false;
    console.log(this.feedbackForm);
  },
  computed: {
    config() {
      return config.state.config;
    },
    user() {
      return auth.state.user;
    },

  },
}
</script>
