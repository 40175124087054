<template>
  <div>
    <h5 v-if="title" class="card-title mb-3">{{ title }}</h5>
    <div class="border border-secondary rounded p-3">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"]
}
</script>
