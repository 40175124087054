<template>
<div class="accordion">
  <slot/>
</div>
</template>

<script>
export default {
  name: "AccordComp"
}
</script>

<style scoped>

</style>