<template>

  <router-link v-if="!to.startsWith('http')" :to="{name: to}" custom v-slot="{ navigate, isActive }">
    <a class="nav-link d-flex fs-5" :class="{active: isActive}" href="#" @click="navigate">
        <i class="bi align-self-center mx-3" :class="icon"/>
        <div class="align-self-center text-truncate lead"><slot/></div>
    </a>
  </router-link>
  <a v-if="to.startsWith('http')" class="nav-link d-flex fs-5" :class="{active: isActive}" :href="to" target="_blank">
    <i class="bi align-self-center mx-3" :class="icon"/>
    <div class="align-self-center text-truncate lead"><slot/></div>
  </a>
</template>

<script>
export default {
  props: ["to", "name", "icon"]
}
</script>

<style>
.nav-link {
  height: 60px;
}

.nav-link.active {
  background-color:  rgba(94,194,105,0.25);
  color: #5EC269;
}
.nav-link.active:focus {
  background-color:  rgba(94,194,105,0.25);
  color: #5EC269;
}
.nav-link.active:hover {
  background-color:  rgba(94,194,105,0.25);
  color: #5EC269;
}
</style>
