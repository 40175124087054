<template>
  <card-comp title="Admin actions" class="mb-2">
    <div v-if="error" class="text-danger">{{ error }}</div>
    <button @click="clearCache" class="btn btn-primary" :disabled="loading">
      <l-spin v-if="loading"/>
      Clear S3 cache
    </button>
  </card-comp>
</template>

<script>
import CardComp from "@/components/ui/CardComp";
import LSpin from "@/components/ui/LSpin";
import {http} from "@/misc/http";

export default {
  components: {LSpin, CardComp},
  data() {
    return {
      error: "",
      loading: false,
    }
  },
  methods: {
    async clearCache() {
      this.error = "";
      this.loading = true;
      try {
        this.downloads = await http.delete('/admin/cache');
        alert("Successfuly cleared")
      } catch (e) {
        this.error = e.message;
      }
      this.loading = false;

    }
  }
}
</script>
