import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import "bootstrap-icons/font/bootstrap-icons.css"
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap-dark-5/dist/css/bootstrap-nightshade.min.css'
import "./style.scss"

import {auth} from "@/state/auth";
import LoadScript from "vue-plugin-load-script";
import {config} from "@/state/config";

// document.documentElement.classList.add('dark');
// document.documentElement.style.setProperty("color-scheme", 'dark');


config.load()
  .then(() => auth.tryLogin())
  .then(() => createApp(App)
    .use(router)
    .use(LoadScript)
    .mount('#app')
  );


// auth.tryLogin()
//   .then(() => http.get('/config'))
//   .then((config) => {
//     console.log(config);
//     createApp(App)
//       .use(router)
//       .use(LoadScript)
//       // .use(VueReCaptcha, {siteKey: config?.ALP_RECAPTCHA_SITE_KEY})
//       .mount('#app');
//   })

