<template>
  <div></div>
</template>

<script>
import {auth} from "@/state/auth";

export default {
  mounted() {
    auth.logout();
    this.$router.replace({name: "signin"});
  }
}
</script>

<style scoped>

</style>