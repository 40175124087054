<template>
  <table class="table table-hover table-borderless">
    <thead v-if="header">
    <tr>
      <!--      style="cursor: pointer"-->
      <th v-for="v in header" :key="v" scope="col" class="text-nowrap">{{ v }}</th>
    </tr>
    </thead>

    <tbody>
    <tr v-for="row in rows" :key="row" @click="$emit('row-click', row)" style="cursor: pointer">
      <td v-for="(v, i) in row" :key="v" :class="{'fw-bold':i==0&&rowsHeading}">{{ v }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["header", "rows", "rows-heading"],
  emits: ["row-click"],
}
</script>

<style scoped>

</style>