<style>
.text-strikethrough {
  text-decoration: line-through;
}

#current-plan {
  background-color: #212936;
  border-color: #212936 !important;
}
</style>

<template>
  <div class="col-lg p-3">
    <div :id="plan.selected ? 'current-plan' : null" class="border rounded p-3 h-100 d-flex flex-column gap-2" :class="{'border-secondary': !plan.selected}">
      <h4>{{ plan.title }}</h4>
      <span v-html="plan.description"/>
      <div>
        <div v-for="f in plan.features" :key="f" class="d-flex gap-2 mt-3">
          <i v-if="!plan.disabled_features.includes(f)" class="bi bi-check-lg"/>
          <div v-if="!plan.disabled_features.includes(f)" class="align-self-center text-truncate">{{ f }}</div>
          <i v-if="plan.disabled_features.includes(f)" class="bi bi-ban"/>
          <div v-if="plan.disabled_features.includes(f)" class="align-self-center text-truncate text-strikethrough">{{ f }}</div>
        </div>
        <div v-for="f in plan.extra_features" :key="f" class="d-flex gap-2 mt-3">
          <i class="bi bi-plus-lg text-success"/>
          <div class="align-self-center text-truncate text-success">{{ f }}</div>
        </div>
      </div>
      <br>
      <button v-if="plan.id !== 'trial' && !plan.selected" @click="$emit('show-feedback')" class="btn btn-outline-primary mt-auto">Request a Switch</button>
      <button v-if="plan.selected" class="btn btn-secondary mt-auto active-plan" disabled>Active plan</button>
    </div>
  </div>

</template>

<script>
export default {
  props: ["plan"],
  emits: ["show-feedback"],
}
</script>
<style>
.btn.btn-secondary.mt-auto.active-plan{
  color: white;
  background-color: rgba(255,255,255,0.25);
}
</style>
