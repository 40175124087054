<template>
  <div class="container-fluid">
    <img src="@/assets/logo.svg" alt="" class="img-fluid mx-3 my-3">
    <div class="row justify-content-center mt-5">
      <card-comp v-if="!result || error" :title="'Please ' + (signupMode ? 'Sign-Up' : 'Sign-In')" style="max-width: 540px;">
        <div v-if="result" class="text-danger mb-3"> {{ result }}</div>
        <form @submit.prevent="submit">
          <div class="mb-3">
            <label for="email" class="form-label">{{ signupMode ? 'Your work email' : 'Email address' }}</label>
            <input v-model="email" type="email" class="form-control" id="email">
          </div>
          <div v-if="signupMode" class="mb-3">
            <label for="purpose" class="form-label">How are you planning to use Alchemist?</label>
            <textarea v-model="purpose" class="form-control" id="purpose" rows="3"></textarea>
          </div>
          <button @click.prevent="submit" :disabled="!isValid || !!loading" type="submit" class="btn btn-primary mb-3">
            {{ signupMode ? 'Sign-Up' : 'Sign-In' }}
            <l-spin v-if="loading"/>
          </button>
          <div v-if="!signupMode" class="form-text">Not registered yet? Please <a @click.prevent="switchToSignup" href="#">Sign-Up</a> first!</div>
          <div v-if="signupMode" class="form-text">Already registered? Just <a @click.prevent="switchToSignin" href="#">Sign-In</a>!</div>
        </form>
      </card-comp>
      <card-comp v-else title="Success" style="max-width: 540px;">
        <div class="text-success mb-3"> {{ result }}</div>
        <div> You can close this page.</div>
      </card-comp>
    </div>
  </div>
</template>

<script>
import { http } from "@/misc/http";
import CardComp from "@/components/ui/CardComp";
import LSpin from "@/components/ui/LSpin";
import { config } from "@/state/config";
import NavBar from "@/components/navigation/NavBar";

export default {
  name: "AuthForm",
  components: { NavBar, LSpin, CardComp },
  data() {
    return {
      email: "",
      purpose: "",
      signupMode: false,
      loading: false,
      result: "",
      error: false,
      embed: false,
    };
  },

  created() {
    this.embed = this.$route.query?.embed || false;
    this.result = history.state?.message || "";
    if (this.result) this.error = true;

    // Check for mode parameter in URL
    const mode = this.$route.query?.mode;
    if (mode === "signup") {
      this.signupMode = true;
    } else if (mode === "signin") {
      this.signupMode = false;
    }
  },

  async mounted() {
    const recaptcha_script = "https://www.google.com/recaptcha/api.js?render=" + config.state.config?.ALP_RECAPTCHA_SITE_KEY;
    await this.$loadScript(recaptcha_script);
  },

  async beforeUnmount() {
    const recaptcha_script = "https://www.google.com/recaptcha/api.js?render=" + config.state.config?.ALP_RECAPTCHA_SITE_KEY;
    await this.$unloadScript(recaptcha_script);
  },

  computed: {
    isValid() {
      return !!this.email;
    },
  },

  methods: {
    async submit() {
      this.loading = true;

      try {
        const resp = await http.post('/auth/sign', {
          email: this.email,
          purpose: this.purpose,
          signup_mode: this.signupMode,
          redirect: this.$route.query?.redirect || null,
          recaptcha: '',
        });

        this.result = resp.result;
        this.error = !resp.success;
        if (resp.success) {
          this.email = "";
          this.purpose = "";
        }
        if (resp.force_signup) this.signupMode = true;

      } catch (e) {
        this.error = true;
        this.result = e.message;
      }

      this.loading = false;
    },

    switchToSignup() {
      this.signupMode = true;
      this.error = false;
      this.result = "";
      this.$router.push({ query: { ...this.$route.query, mode: 'signup' } });
    },

    switchToSignin() {
      this.signupMode = false;
      this.error = false;
      this.result = "";
      this.$router.push({ query: { ...this.$route.query, mode: 'signin' } });
    },
  },
};
</script>
