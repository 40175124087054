<template>
  <div class="row justify-content-center mt-5">
    <card-comp title="Get offline license" style="max-width: 600px;">
      <form>
        <div class="mb-3">
          <p>You are about to create or renew your offline license.</p>
          <p>An offline license is only necessary if you cannot activate your license key in the standard way, for example, if you have no internet connection on the machine where you run Alchemist.</p>
          <p>The offline license has a limited lifetime of {{ config.ALP_LICENSE_OFFLINE_EXPIRE_MONTH }} months. You can create or renew it on this page.</p>

          <p v-if="nofingerprint">To create or renew your offline license, please run the following command:</p>
          <pre v-if="nofingerprint">alchemist license offline</pre>
          <p v-if="nofingerprint">Then, follow the provided link on the machine where you can log into the Alchemist Portal.</p>

          <p class="fw-bold">Please do not create an offline license if you can activate your key in the standard way!</p>
        </div>

        <div v-if="error" class="text-danger mb-3">{{ error }}</div>
        <!--        <div v-if="nofingerprint" class="mb-3">-->
        <!--          <label for="fingerprint" class="form-label">Machine fingerprint</label>-->
        <!--          <input v-model="fingerprint" type="text" class="form-control" id="fingerprint">-->
        <!--        </div>-->

        <div class="d-flex gap-2">
          <button @click="downloadLicense" type="button" class="btn btn-primary" :disabled="!valid || loading">
            <l-spin v-if="loading"/>
            Create or renew your offline license and download the license.dat file
          </button>

          <button v-if="license_dat" @click="doCopy" type="button" class="btn btn-outline-secondary"><i class="bi bi-clipboard"/> If the download doesn't start, click here</button>
        </div>
      </form>

    </card-comp>
  </div>
</template>

<script>
import CardComp from "@/components/ui/CardComp";
import LSpin from "@/components/ui/LSpin";
import {auth} from "@/state/auth";
import {config} from "@/state/config";
import {http} from "@/misc/http";

export default {
  components: {LSpin, CardComp},
  data() {
    return {
      nofingerprint: true,
      fingerprint: "",
      loading: false,
      error: false,
      license_dat: null,
    }
  },

  created() {
    if (this.$route.query.m) {
      this.fingerprint = this.$route.query.m;
      this.nofingerprint = false;
    }

  },

  computed: {
    user() {
      return auth.state.user;
    },
    config() {
      return config.state.config;
    },
    valid() {
      return this.$route.query.hash && this.fingerprint;
    },
  },

  methods: {
    async downloadLicense() {
      this.error = "";
      this.loading = true;
      this.license_dat = null;
      try {
        this.license_dat = await http.post('/downloads/machine', {
          license_hash: this.$route.query.hash,
          machine_fingerprint: this.fingerprint,
        });

        const blob = new Blob([this.license_dat], {
          type: 'text/plain'
        });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = "license.dat";
        anchor.click();

      } catch (e) {
        this.error = e.message;
      }
      this.loading = false;
    },

    doCopy() {
      navigator.clipboard.writeText(this.license_dat);
      alert("License copied into clipboard. Please create a text file using any text editor, such as Notepad, paste the license into it, and save the file as 'license.dat'.")
    },

  },
}
</script>
