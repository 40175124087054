<template>
  <div class="container mt-3">
    <div v-if="error" class="text-danger">{{ error }}</div>
    <div v-if="eula" v-html="eula"/>
  </div>
</template>

<script>
import {http} from "@/misc/http";

export default {
  data() {
    return {
      eula: null,
      error: "",
    }
  },
  async created() {
    try {
      this.eula = await http.get("/eula/full");
    } catch (e) {
      this.error = e.message;
    }
  },
}
</script>
