<template>
  <div v-if="error" class="text-danger">{{ error }}</div>
  <l-spin v-if="!download"/>
  <div v-else class="d-flex flex-column">
    <div class="readme" v-html="download[opened?'readme_html':'readme_html_short']"/>
    <a v-if="!opened" @click="opened=true" href="#" class="ms-auto text-secondary">Show more...</a>

    <!-- <div v-if="!link" class="dropdown">
      <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" :class="{disabled: !!loading}">
        Choose your platform
      </button>
      <ul class="dropdown-menu">
        <li v-for="p in platforms" :key="p">
          <a @click.prevent="doDownload(p)" href="#" class="dropdown-item">{{ p }}</a>
        </li>
      </ul>
    </div> -->
    <div v-if="!link" >
      <button v-for="p in platforms" :key="p" class="btn btn-primary mx-1" @click.prevent="doDownload(p)">
        {{ p }} 
        <i class="bi" :class="icons[p]"></i>
      </button>
    </div>
    <div v-else class="d-flex gap-2">
      <a :href="link" class="btn btn-success">Click here if download for {{ platform }} didn't start</a>
      <button @click="link=null" class="btn btn-outline-secondary">Choose another platform</button>
    </div>
  </div>

</template>

<script>
import {http} from "@/misc/http";
import LSpin from "@/components/ui/LSpin";

export default {
  components: {LSpin},
  props: ["ver"],
  emits: ["show-eula"],

  data() {
    return {
      download: null,
      link: null,
      platform: null,
      error: "",
      loading: false,
      opened: false,
      icons:{
        "macos":"bi-apple",
        "macos-arm":"bi-apple",
        "windows":"bi-windows",
      },
    }
  },
  async created() {
    try {
      this.download = await http.get('/downloads/' + this.ver);
      this.opened = this.download.readme_html == this.download.readme_html_short;
    } catch (e) {
      this.error = e.message;
    }
  },
  computed: {
    defaultPlatform() {
      return this.download?.platforms[0];
    },
    platforms() {
      return this.download?.platforms;
    },
  },
  methods: {
    async doDownload(platform) {
      this.loading = true;
      this.platform = platform;
      try {
        this.link = await http.get('/downloads/' + this.ver + '/link/' + platform);
        if (this.link) {
          const anchor = document.createElement('a');
          anchor.href = this.link;
          const path = anchor.pathname.split('/');
          anchor.download = path[path.length - 1];
          anchor.click();
        } else {
          this.$emit("show-eula");
        }
      } catch (e) {
        this.error = e.message;
      }
      this.loading = false;
    },


  },

}
</script>
<style>
  .readme h3{
    font-size: 1.5rem;
    color: rgba(255,255,255,0.9);
  }
  .readme h2{
    font-size: 1.7rem;
    color: rgba(255,255,255,0.9);
    }
</style>

