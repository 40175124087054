import jwtDecode from "jwt-decode";
import router from "@/router";
import {http} from "@/misc/http";
import {reactive} from "vue";

const auth = {
  token: null,
  logout_timer: null,
  state: reactive({
    user: null,
  }),

  _clear() {
    localStorage.removeItem("token");
    this.token = null;
    this.state.user = null;
  },

  _clearAutologout() {
    if (this.logout_timer) clearTimeout(this.logout_timer);
    this.logout_timer = null;
  },

  async loadUser(){
      this.state.user = await http.get("/auth/user");

  },
  async login(token) {
    this._clearAutologout();
    try {
      const claim = jwtDecode(token);
      const exp_in = claim.exp * 1000 - Date.now();
      if (exp_in < 10000) throw Error();
      this.token = token;
      localStorage.setItem("token", token);
      await this.loadUser();

      this.logout_timer = setTimeout(() => {
        this.logout();
        router.push({
          name: "signin",
          query: {
            redirect: window.location.pathname,
          },
          state: {message: "Your login session has been expired, please sign-in."}
        });
      }, claim.exp * 1000 - Date.now());
    } catch (e) {
      this._clear();
    }
  },

  logout() {
    this._clearAutologout();
    this._clear();
  },

  async tryLogin() {
    await this.login(localStorage.getItem("token"));
  },

  isLoggedIn() {
    return !!this.token;
  },

}


export {
  auth
}