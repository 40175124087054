<template>

  <b-modal ref="eula" @modal-closed="acceptEULA" title="Alchemist End-User License Agreement" okbutton="I Agree">
    <div v-html="eula"/>
  </b-modal>
  <b-modal ref="eula_raw" title="Alchemist End-User License Agreement" okbutton="Close" :cancelbutton="false">
    <div v-html="eula"/>
  </b-modal>

  <page-header title="Downloads">
    The following is chronological list of previous versions of the Alchemist:
  </page-header>


  <div v-if="error" class="text-danger">{{ error }}</div>

  <l-spin v-if="loading"/>

  <!--  <div class="row">-->
  <!--    <div class="col-8">-->
    <div class="row" id="wrapper">
        <accord-comp class="col-lg-9" id="downloads">
      <accord-item
          v-for="(v,i) in downloads"
          :key="v"
          :id="v.replace('.', '-')"
          :title="v"
          parent="downloads"
          :show="ver?v==ver:i==0"
          :href="'/downloads/'+v"
      >
        <download-comp :ver="v" @show-eula="showEULA(v)" :ref="el=>{refs[v]=el}"/>
      </accord-item>
    </accord-comp>
    <!-- <admin-actions class=" bg-dark col-3 h-100 p-4 rounded ml-2"/> -->
    <admin-actions v-if="['admin'].includes(user?.status)" class="col-lg-9 mb-2 mt-5"/>
    </div>

</template>

<script>
import {http} from "@/misc/http";
import DownloadComp from "@/pages/downloads/DownloadComp";
import LSpin from "@/components/ui/LSpin";
import {auth} from "@/state/auth";
import BModal from "@/components/ui/BModal";
import {config} from "@/state/config";
import AccordComp from "@/components/ui/AccordComp";
import AccordItem from "@/components/ui/AccordItem";
import AdminActions from "@/pages/downloads/AdminActions";
import CardComp from "@/components/ui/CardComp";
import PageHeader from "@/components/PageHeader";

export default {
  components: {PageHeader, CardComp, AdminActions, AccordItem, AccordComp, BModal, LSpin, DownloadComp},
  props: ["ver"],
  data() {
    return {
      downloads: null,
      error: "",
      loading: false,
      eula: null,
      refs: {},
      accepting: null,
    }
  },

  async created() {
    this.loading = true;
    try {
      this.eula = await http.get("/eula/full");
      this.downloads = await http.get('/downloads/');

      if (this.ver) {
        // noinspection ES6MissingAwait
        this.$nextTick(() => {
          const el = document.getElementById(this.ver.replace('.', '-'));
          console.log(el, this.ver.replace('.', '-'));
          if (el) {
            el.scrollIntoView({behavior: "smooth"});
          }
        });
      }

    } catch (e) {
      this.error = e.message;
    }
    this.loading = false;
  },


  computed: {
    user() {
      return auth.state.user;
    },
    config() {
      return config.state.config;
    },

  },
  methods: {
    showMore() {
      if (this.opened < this.downloads.length)
        this.opened++;
    },

    showEULA(ver) {
      if (this.accepting) return;
      this.accepting = ver;
      this.$refs.eula.show();
    },

    async acceptEULA(result) {
      if (result) {
        this.loading = true;
        try {
          this.link = await http.post('/eula', this.eula);
          const download = this.refs[this.accepting];
          // noinspection ES6MissingAwait
          download.doDownload(download.platform);
        } catch (e) {
          this.error = e.message;
        }
        this.loading = false;
      }
      this.accepting = null;
    },
  }
}
</script>
