<template>
  <b-modal ref="modal" @modal-closed="onModalClose" :title="title" okbutton="Send">
    <div class="d-flex flex-column gap-2">
      <p>{{ description }}</p>
      <textarea v-model="input" class="form-control mb-3" rows="5"></textarea>
    </div>
  </b-modal>
</template>

<script>
import BModal from "@/components/ui/BModal";
import {http} from "@/misc/http";

export default {
  components: {BModal},

  data() {
    return {
      title: "Have question?",
      description: "",
      input: "",
    }
  },
  methods: {
    show(title, description, input) {
      if (title) this.title = title;
      if (description) this.description = description;
      if (input) this.input = input;
      this.$refs.modal.show();
    },
    onModalClose(result) {
      if (result && this.input) {
        http.post("/feedback", this.input);
      }
    }
  }
}
</script>
