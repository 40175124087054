<template>
  <div v-on="{'hidden.bs.modal': onHidden}" ref="modal" class="modal modal-xl fade" tabindex="-1">
    <div class="modal-dialog modal-lg ">
      <div class="modal-content p-2">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot/>
        </div>
        <div class="modal-footer">
          <button v-if="okbutton!==false" @click="hide(true)" type="button" class="btn btn-primary">{{ okbutton || "Ok" }}</button>
          <button v-if="cancelbutton!==false" @click="hide(false)" type="button" class="btn btn-secondary">{{ cancelbutton || "Cancel" }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";

export default {
  props: ["title", "okbutton", "cancelbutton"],
  emits: ["modal-closed"],
  data() {
    return {
      result: false,
    }
  },
  methods: {

    show() {
      this.result = false;
      this.modal = Modal.getOrCreateInstance(this.$refs.modal);
      this.modal.show();
    },

    hide(result) {
      this.result = result;
      this.modal.hide();
    },

    onHidden() {
      this.$emit("modal-closed", this.result);
    }
  }
}
</script>

<style scoped>

</style>