<template>
  <div class="d-flex fs-5 text-light mt-3">
    <span class="ms-auto d-flex gap-3 align-items-center flex-wrap text-light">
      <i class="bi bi-person-circle fs-4 text-light"></i>
      {{ user.email }} <span v-if="user?.status=='admin'">[Admin]</span>
      <button @click="$router.replace({name:'logout'})" class="btn btn-outline-secondary">Logout</button>
    </span>
  </div>

</template>

<script>
import {auth} from "@/state/auth";

export default {
  computed: {
    user() {
      return auth.state.user;
    }
  }
}
</script>

<style scoped>

</style>