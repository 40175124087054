<template>

  <card-comp :title="user.email + (this.user.status=='admin'?' [Admin]':'')">
    <table-comp
        :rows="userInfo"
        :rows-heading="true"
    />
    <user-actions :user="user"/>
  </card-comp>

</template>

<script>
import TableComp from "@/components/ui/TableComp";
import CardComp from "@/components/ui/CardComp";
import UserActions from "@/pages/users/UserActions";

export default {
  components: {UserActions, CardComp, TableComp},
  props: ["user"],
  computed: {
    userInfo() {
      return [
        ["Status", this.userStatus],
        ["ID", this.user.id],
        ["Purpose for registration", this.user.profile.description],
        ["Permitted purpose (when issuing license)", this.user.permitted_purpose],
        // ["Block reason", this.user.block_reason],
        ["License key", this.user.license_key ? this.user.license_key.slice(0, 16) + '...' : 'No license'],
      ]
    },
    userStatus() {
      return {
        admin: "Admin",
        registered: "Waiting for approval",
        verified: "Approved",
        blocked: "Blocked",
      }[this.user.status]
    }
  }

}
</script>
