// import fetch from "/usr/local/lib/node_modules/node-fetch/lib/index.js";

import * as contentDisposition from 'content-disposition';
import {auth} from "@/state/auth.js";

function make_query(obj) {
  let str = [];
  for (let p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      let val = obj[p];
      if (typeof val == 'object') val = JSON.stringify(val)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(val));
    }
  return str.join("&");
}

const http = {
  api: '/api/v1',

  async request({path, method, query, body, formdata, response = 'json'}) {

    const token = auth.token;
    const headers = token ? {
      'Authorization': 'Bearer ' + token
    } : {}


    const query_str = query ? '?' + make_query(query) : '';
    const url = this.api + path + query_str;

    let request;

    if (formdata) {
      const formdata_ = new FormData();

      for (const k in formdata) formdata_.append(k, formdata[k])

      request = {
        method: method,
        headers: {
          ...headers
        },
        body: formdata_
      }
    } else {
      const body_ = body ? {body: JSON.stringify(body)} : {};

      request = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          ...headers
        },
        ...body_
      }
    }

    // console.log(url, JSON.stringify(request))

    const resp = await fetch(url, request);

    if (!resp.ok) throw Error(await resp.text());

    if (resp.status == 204) return;

    if (response == 'json') {
      return await resp.json()
    } else if (response == 'blob') {
      const blob = await resp.blob();
      const header = resp.headers.get('Content-Disposition');
      return [blob, contentDisposition.parse(header).parameters.filename]
    }

  },

  async get(path, query) {
    return await this.request({
      method: 'GET',
      path: path,
      query: query,
    });
  },

  async getBlob(path, query) {
    return await this.request({
      method: 'GET',
      path: path,
      query: query,
      response: 'blob'
    });
  },

  async post(path, data) {
    return await this.request({
      method: 'POST',
      path: path,
      body: data
    })
  },

  async post_form(path, formdata) {
    return await this.request({
      method: 'POST',
      path: path,
      formdata
    })
  },

  async put(path, data) {
    return await this.request({
      method: 'PUT',
      path: path,
      body: data
    })
  },

  async patch(path, data) {
    return await this.request({
      method: 'PATCH',
      path: path,
      body: data
    })
  },

  async delete(path, query) {
    return await this.request({
      method: 'DELETE',
      path: path,
      query: query
    })
  },

}

export {
  http
}